import Helmet from "react-helmet"
import React from "react"
import PostDLsteps from "src/components/postDLsteps"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"

const stepsData = import("./data/data.json");

export default function downloadSteps() {
  return(
    <section>
      <Helmet>
      <style type="text/css">{`

        }
    `}</style>

        <title>You're almost done! - Clearbar.app</title>
      </Helmet>
      <>
      <PostDLsteps data={stepsData}></PostDLsteps>
      </>
    </section>
  )
}
